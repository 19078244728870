<template>
  <div
    v-if="showModal"
    class="modal"
    :class="{ '__custom-styles': customStyle, [name + '-modal']: !!name }"
    :style="{ '--max-width': maxWidth }"
  >
    <Loader v-if="isLoading" />
    <template v-else>
      <div class="modal__overlay" @click="onHideModal"></div>
      <div class="modal__wrapper">
        <div class="modal__content">
          <slot name="header" :payload="payloadData" :closeModal="onHideModal">
            <div v-if="!hideHeader" class="modal__header">
              <slot name="title" :payload="payloadData" :closeModal="onHideModal">
                <div v-if="title" class="modal__title">
                  {{ title }}
                </div>
              </slot>
              <slot name="close-button" :payload="payloadData" :closeModal="onHideModal">
                <base-icon
                  v-if="!hideCloseBtn"
                  class="modal__close-btn"
                  width="16"
                  height="16"
                  screen-size-dependency
                  :path="'Close-grey.svg'"
                  @click="onHideModal"
                />
              </slot>
            </div>
          </slot>
          <slot :payload="payloadData" :closeModal="onHideModal" />
        </div>
        <slot name="buttons-wrapper" :payload="payloadData" :closeModal="onHideModal">
          <div v-if="!hideFooterButtons" class="modal__buttons-wrapper">
            <base-button is-new tertiary small @click="onHideModal">Отмена</base-button>
            <base-button is-new primary small @click="$emit('submit')">Применить</base-button>
          </div>
        </slot>
      </div>
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, toRef, watch } from "vue";
import emitter from "@/lib/helpers/emitter";
import { EVENTS } from "@/const";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";
import BaseButton from "@/components/BaseButton/BaseButton.vue";
import { useStore } from "vue2-helpers/vuex";
import { MUTATION_TYPES } from "@/store/const";
import { useRouter } from "vue2-helpers/vue-router";
import Loader from "@/components/Loader.vue";

const store = useStore();
const router = useRouter();
const emits = defineEmits(["update:payload", "update:show", "apply"]);
const props = defineProps({
  name: String,
  title: String,
  hideCloseBtn: Boolean,
  hideHeader: Boolean,
  hideFooterButtons: Boolean,
  customStyle: Boolean,
  maxWidth: { type: String, default: "100%" },
  show: { type: Boolean, default: false },
  isLoading: { type: Boolean },
});

const showModal = ref(props.show);
const payloadData = ref();

const noflow = () => {
  document.body.style.overflow = "hidden";
  document.body.style.userSelect = "none";
};

const haveflow = () => {
  document.body.style.overflow = "auto";
  document.body.style.userSelect = "auto";
};

const onShowModal = payload => {
  if (payload.name !== props.name) return;
  console.log("pay", payload);
  showModal.value = true;
  payloadData.value = payload;
  noflow();
  emits("update:show", showModal.value);
  store.commit(MUTATION_TYPES.SET_MODAL_PAYLOAD, payload);
  payload?.route && router.push({ name: payload.route });
};

const apply = () => {
  emits("apply");
};

const onHideModal = () => {
  haveflow();
  showModal.value = false;
  emits("hiddenModal", showModal.value);
  payloadData.value?.route && router.back();
  payloadData.value = {};
};

const handleKeyPress = e => {
  if (e.key === "Escape") {
    onHideModal();
  }
};

watch(payloadData, () => {
  emits("update:payload", payloadData.value);
});

watch(toRef(props, "show"), newVal => {
  showModal.value = newVal;
});

onMounted(async () => {
  emitter.on(EVENTS.SHOW_MODAL, onShowModal);
  emitter.on(EVENTS.HIDE_MODAL, onHideModal);
  document.addEventListener("keyup", handleKeyPress);
});

onUnmounted(() => {
  emitter.off(EVENTS.SHOW_MODAL, onShowModal);
  emitter.off(EVENTS.HIDE_MODAL, onHideModal);
  document.removeEventListener("keyup", handleKeyPress);
});
</script>

<style scoped lang="scss">
@import "@/styles/ui/breakPoints";
@import "@/styles/ui/fontSize";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background: rgba(15, 23, 42, 0.2);
  }

  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &:not(.__custom-styles) {
    .modal {
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: var(--sp-3);
      }

      &__title {
        //color: var(--color-neutral-900);
        @include add-font-face("h4");
      }

      &__content {
        padding: var(--sp-6);
        background: var(--color-basic-white);
        border-radius: var(--sp-1) var(--sp-1) 0 0;
      }

      &__close-btn {
        padding: var(--sp-ex-10);
        cursor: pointer;
      }

      &__buttons-wrapper {
        display: flex;
        gap: var(--sp-6);
        padding: var(--sp-4) var(--sp-6);
        background: var(--color-basic-white);
        border-radius: 0 0 var(--sp-1) var(--sp-1);
        border-top: 1px solid var(--color-neutral-200);

        .base-button-new {
          width: 100%;
        }
      }
    }
  }
}

@include mobile-up {
  .modal__wrapper {
    max-width: var(--max-width);
  }
}

@include mobile-only {
  .modal {
    position: fixed;
    width: 100%;
    left: 0;
    top: 48px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    gap: var(--sp-6);

    &__overlay {
      display: none;
    }

    &__wrapper {
      position: initial;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 0;
      transform: none;
    }

    &:not(.__custom-styles) {
      .modal {
        &__content {
          padding: var(--sp-4);
        }

        &__close-btn {
          display: none;
        }
      }
    }
  }
}
</style>
